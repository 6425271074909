class Modal {
    constructor() {
        this.init();
        this.scroll = 0;
    }
    init() {
        $('.js-modal').on('click', (e) => {
            let madalname = $(e.currentTarget).data('modalname');
            this.modalOpen(madalname);
        });
        $('.js-modal-close,#md-gray-layer').on('click', () => {
            this.modalClose();
        })
        $('#md-over-layer').on('click', (e) => {
            if ($(e.target).attr('id') == 'md-over-layer') {
                this.modalClose();
            }
        })
    }

    modalClose() {
        $('#md-gray-layer,#md-over-layer').fadeOut(100);
        $('body').removeClass('is-modal');
        $(window).scrollTop(this.scroll);
    }

    modalOpen(target) {
        $('#md-gray-layer,#md-over-layer').fadeIn(200);
        $('#' + target).show(0).siblings('.js-modal-item').hide(0);
        $('#md-over-layer').scrollTop(0);
        this.scroll = $(window).scrollTop();
        $('body').addClass('is-modal').css('top', this.scroll * -1);
    }
}

export default Modal;