import Menu from './Menu';
import Tab from './Tab';
import Modal from './Modal';
import Fade from './Fade';

$(function () {
    new Menu();
    new Modal();
    new Tab();
    new Fade();
    $('.js-page-top').on('click',()=>{
        $('html,body').animate({scrollTop: 0}, 300, 'swing');
    })
});