class Tab {
    constructor() {
        this.init();
    }
    init() {
        $('.js-tab-switch').on('click', (e) => {
            $(e.currentTarget).addClass('is-select').siblings().removeClass('is-select');
            let target = $(e.currentTarget).find('a').attr('href');
            this.content_visible(target);
            $('.js-tab-switchs').attr('data-current', target)
            return false;
        });

        var tab = null;
        var match = location.search.match(/tab=(.*?)(&|$)/);
        if (match) {
            tab = +decodeURIComponent(match[1]);
            tab = Math.max(1, tab);
            tab = Math.min($('.js-tab-switch').length, tab);
            if (tab) {
                $('.js-tab-switchs').find('.js-tab-switch').eq(tab - 1).trigger('click');
            }
        }

        var item = null;
        var match = location.search.match(/item=(.*?)(&|$)/);
        if (match) {
            item = +decodeURIComponent(match[1]);
            item = Math.max(1, item);
            let targetBox = $('.js-tab-content.is-active').find('.js-modal');
            item = Math.min(targetBox.length, item);
            if (item) {
                setTimeout(() => {
                    targetBox.eq(item - 1).trigger('click');
                }, 1000);
            }
        }
    }

    content_visible(e) {
        $(e).addClass('is-active').siblings().removeClass('is-active');
    }
}

export default Tab;