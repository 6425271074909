class Fade {
    constructor() {
        $(window).on('scroll load', () => {
            let h = $(window).scrollTop() + $(window).outerHeight();
            $('.js-fade').each((i, e) => {
                let target = $(e);
                let top = target.offset().top;
                if (top < h - 50) {
                    target.addClass('is-visible').removeClass('js-fade');
                }
            })
        })
    }
}

export default Fade;