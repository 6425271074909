class Menu {
    constructor() {
        this.menu = $('.js-menu-list');
        this.menu_switch = $('.js-menu-switch');
        this.init();
    }

    init() {
        this.menu_switch.on('click', (e) => {

            if ($(e.currentTarget).hasClass('is-active')) {
                this.menu_switch.removeClass('is-active');
                this.menu.stop(true, true).slideUp(200);
            } else {
                this.menu_switch.addClass('is-active');
                this.menu.stop(true, true).slideDown(200);
            }
        })

        $('.js-menu-close').on('click', (e) => {
            this.menu_switch.removeClass('is-active');
            this.menu.stop(true, true).slideUp(200);
        })
    }
}

export default Menu;